import {
  AgeType,
  BinaryGender,
  BodyType,
  BodyTypeCalorieMultiplier,
  IBodyType,
  IBreed,
  LifeStyle,
  LifeStyleCalorieMultiplier,
  MealPlanCalories,
  PouchSize,
  RetailTrayPortions,
} from "./cat-calculations.types";

export const WEEKS_PER_MONTH = 4.33;
export const CALORIE_THRESHOLD = 25;

export const KITTEN_AGE_MONTHS: number[] = [
  0, 1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11,
];
export const KITTEN_AGE_WEEKS: number[] = [ 0, 1, 2, 3, 4 ];

export const ADULT_CAT_AGE_YEARS: number[] = [ 1, 2, 3, 4, 5, 6, 7, 8 ];
export const SENIOR_CAT_AGE_YEARS: number[] = [
  9, 10, 11, 12, 13, 14, 15, 16, 17, 18, 19, 20,
];

export const AGE_TYPE: Record<AgeType, string> = {
  kitten: "Kitten (Under 1 year old)",
  adult: "Adult (1-8 years old)",
  senior: "Senior (Over 8 years old",
};

export const BINARY_GENDER: Record<BinaryGender, string> = {
  BOY: "Boy",
  GIRL: "Girl",
} as const;

export const LIFESTYLE: Partial<Record<LifeStyle, string>> = {
  INACTIVE: "Inactive",
  ACTIVE: "Active",
  EXPLORER: "Explorer",
} as const;

export const BODY_TYPE: Partial<Record<BodyType, IBodyType>> = {
  SKINNY: {
    name: "Thin",
    icon: "catWeightSkinny",
  },
  JUST_RIGHT: {
    name: "Just right",
    icon: "catWeightJustRight",
  },
  CHUBBY: {
    name: "Chubby",
    icon: "catWeightChubby",
  },
  OBESE: {
    name: "Obese",
    icon: "catWeightObese",
  },
};

export const GROWTH_CURVE: number[] = [
  0, 6.9, 12.1, 17.2, 24.1, 32.8, 39.7, 48.3, 55.2, 60.3, 65.5, 70.7, 75.9,
  79.3, 82.8, 86.2, 89.7, 91.4, 93.1, 94.8, 96.6, 97.4, 98.3, 99.1, 100.0,
  100.0, 100.0,
];

export const BODY_TYPE_CALORIE_MULTIPLIER: BodyTypeCalorieMultiplier = {
  THIN: 0.75,
  SKINNY: 0.75,
  JUST_RIGHT: 1,
  CHUBBY: 1.25,
  OBESE: 1.5,
  UNKNOWN: 1,
};

export const LIFESTYLE_CALORIE_MULTIPLIER: LifeStyleCalorieMultiplier = {
  INACTIVE: 0.8,
  ACTIVE: 1,
  EXPLORER: 1.2,
  UNKNOWN: 1,
};

export const MEAL_PLAN_CALORIES: MealPlanCalories = {
  A: 150,
  B: 200,
  C: 250,
  D: 300,
  E: 350,
  F: 400,
};

export const RETAIL_PLAN_PORTION: RetailTrayPortions = {
  150: 0.75,
  200: 1,
  250: 1.25,
  300: 1.5,
  350: 1.75,
  400: 2,
};

export const BREEDS: IBreed[] = [
  {
    value: "DOMESTIC_SHORT_HAIR",
    text: "Domestic Short-hair",
    maleAdultWeight: 4,
    femaleAdultWeight: 4,
    isMoggie: true,
    isPedigree: false,
  },
  {
    value: "DOMESTIC_MEDIUM_HAIR",
    text: "Domestic Medium-hair",
    maleAdultWeight: 4,
    femaleAdultWeight: 4,
    isMoggie: true,
    isPedigree: false,
  },
  {
    value: "DOMESTIC_LONG_HAIR",
    text: "Domestic Long-hair",
    maleAdultWeight: 4,
    femaleAdultWeight: 4,
    isMoggie: true,
    isPedigree: false,
  },
  {
    value: "ABYSSINIAN",
    text: "Abyssinian",
    maleAdultWeight: 4,
    femaleAdultWeight: 3.5,
    isMoggie: false,
    isPedigree: true,
  },
  {
    value: "AMERICAN_BOBTAIL",
    text: "American Bobtail",
    maleAdultWeight: 5,
    femaleAdultWeight: 5,
    isMoggie: false,
    isPedigree: true,
  },
  {
    value: "AMERICAN_CURL",
    text: "American Curl",
    maleAdultWeight: 4,
    femaleAdultWeight: 3,
    isMoggie: false,
    isPedigree: true,
  },
  {
    value: "AMERICAN_SHORTHAIR",
    text: "American Shorthair",
    maleAdultWeight: 5,
    femaleAdultWeight: 5,
    isMoggie: false,
    isPedigree: true,
  },
  {
    value: "BALINESE",
    text: "Balinese",
    maleAdultWeight: 5,
    femaleAdultWeight: 4,
    isMoggie: false,
    isPedigree: true,
  },
  {
    value: "BENGAL",
    text: "Bengal",
    maleAdultWeight: 5.5,
    femaleAdultWeight: 5,
    isMoggie: false,
    isPedigree: true,
  },
  {
    value: "BIRMAN",
    text: "Birman",
    maleAdultWeight: 5,
    femaleAdultWeight: 5,
    isMoggie: false,
    isPedigree: true,
  },
  {
    value: "BRITISH_SHORTHAIR",
    text: "British Short-hair",
    maleAdultWeight: 5.5,
    femaleAdultWeight: 4.5,
    isMoggie: false,
    isPedigree: true,
  },
  {
    value: "BRITISH_LONGHAIR",
    text: "British Long-hair",
    maleAdultWeight: 5,
    femaleAdultWeight: 4.5,
    isMoggie: false,
    isPedigree: true,
  },
  {
    value: "BURMESE",
    text: "Burmese",
    maleAdultWeight: 5,
    femaleAdultWeight: 5,
    isMoggie: false,
    isPedigree: true,
  },
  {
    value: "BURMILLA",
    text: "Burmilla",
    maleAdultWeight: 4,
    femaleAdultWeight: 4,
    isMoggie: false,
    isPedigree: true,
  },
  {
    value: "CHAUSIE",
    text: "Chausie",
    maleAdultWeight: 6,
    femaleAdultWeight: 6,
    isMoggie: false,
    isPedigree: true,
  },
  {
    value: "CHINCHILLA",
    text: "Chinchilla",
    maleAdultWeight: 5,
    femaleAdultWeight: 4,
    isMoggie: false,
    isPedigree: true,
  },
  {
    value: "CORNISH_REX",
    text: "Cornish Rex",
    maleAdultWeight: 3,
    femaleAdultWeight: 3,
    isMoggie: false,
    isPedigree: true,
  },
  {
    value: "DEVON_REX",
    text: "Devon Rex",
    maleAdultWeight: 3,
    femaleAdultWeight: 3,
    isMoggie: false,
    isPedigree: true,
  },
  {
    value: "EXOTIC_SHORTHAIR",
    text: "Exotic Short-hair",
    maleAdultWeight: 5,
    femaleAdultWeight: 4.5,
    isMoggie: false,
    isPedigree: true,
  },
  {
    value: "JAPANESE_BOBTAIL",
    text: "Japanese Bobtail",
    maleAdultWeight: 4,
    femaleAdultWeight: 3,
    isMoggie: false,
    isPedigree: true,
  },
  {
    value: "KORAT",
    text: "Korat",
    maleAdultWeight: 3.5,
    femaleAdultWeight: 3.5,
    isMoggie: false,
    isPedigree: true,
  },
  {
    value: "KURILIAN_BOBTAILS",
    text: "Kurilian Bobtail",
    maleAdultWeight: 4.5,
    femaleAdultWeight: 4.5,
    isMoggie: false,
    isPedigree: true,
  },
  {
    value: "LAPERM",
    text: "LaPerm",
    maleAdultWeight: 4.5,
    femaleAdultWeight: 3.5,
    isMoggie: false,
    isPedigree: true,
  },
  {
    value: "MAINE_COON",
    text: "Maine Coon",
    maleAdultWeight: 7,
    femaleAdultWeight: 6,
    isMoggie: false,
    isPedigree: true,
  },
  {
    value: "MANX",
    text: "Manx",
    maleAdultWeight: 4,
    femaleAdultWeight: 4,
    isMoggie: false,
    isPedigree: true,
  },
  {
    value: "MOGGIE",
    text: "Moggie",
    maleAdultWeight: 4,
    femaleAdultWeight: 4,
    isMoggie: true,
    isPedigree: false,
  },
  {
    value: "NORWEGIAN_FOREST_CAT",
    text: "Norwegian Forest Cat",
    maleAdultWeight: 6.5,
    femaleAdultWeight: 6,
    isMoggie: false,
    isPedigree: true,
  },
  {
    value: "OCICAT",
    text: "Ocicat",
    maleAdultWeight: 4.5,
    femaleAdultWeight: 4.5,
    isMoggie: false,
    isPedigree: true,
  },
  {
    value: "ORIENTAL_SHORTHAIR",
    text: "Oriental Short-hair",
    maleAdultWeight: 4,
    femaleAdultWeight: 3,
    isMoggie: false,
    isPedigree: true,
  },
  {
    value: "PERSIAN",
    text: "Persian",
    maleAdultWeight: 5,
    femaleAdultWeight: 4,
    isMoggie: false,
    isPedigree: true,
  },
  {
    value: "PETERBALD",
    text: "Peterbald",
    maleAdultWeight: 4,
    femaleAdultWeight: 4,
    isMoggie: false,
    isPedigree: true,
  },
  {
    value: "PIXIE_BOB",
    text: "Pixie Bob",
    maleAdultWeight: 5.5,
    femaleAdultWeight: 4.5,
    isMoggie: false,
    isPedigree: true,
  },
  {
    value: "RAGAMUFFIN",
    text: "Ragamuffin",
    maleAdultWeight: 7,
    femaleAdultWeight: 6,
    isMoggie: false,
    isPedigree: true,
  },
  {
    value: "RAGDOLL",
    text: "Ragdoll",
    maleAdultWeight: 6,
    femaleAdultWeight: 5,
    isMoggie: false,
    isPedigree: true,
  },
  {
    value: "RUSSIAN_BLUE",
    text: "Russian Blue",
    maleAdultWeight: 4.5,
    femaleAdultWeight: 4.5,
    isMoggie: false,
    isPedigree: true,
  },
  {
    value: "SAVANNAH",
    text: "Savannah",
    maleAdultWeight: 7,
    femaleAdultWeight: 7,
    isMoggie: false,
    isPedigree: true,
  },
  {
    value: "SCOTTISH_FOLD",
    text: "Scottish Fold",
    maleAdultWeight: 4,
    femaleAdultWeight: 4,
    isMoggie: false,
    isPedigree: true,
  },
  {
    value: "SIAMESE",
    text: "Siamese",
    maleAdultWeight: 4,
    femaleAdultWeight: 3,
    isMoggie: false,
    isPedigree: true,
  },
  {
    value: "SIBERIAN_FOREST_CAT",
    text: "Siberian Forest Cat",
    maleAdultWeight: 7,
    femaleAdultWeight: 6,
    isMoggie: false,
    isPedigree: true,
  },
  {
    value: "SINGAPURA",
    text: "Singapura",
    maleAdultWeight: 3,
    femaleAdultWeight: 3,
    isMoggie: false,
    isPedigree: true,
  },
  {
    value: "SNOWSHOE",
    text: "Snowshoe",
    maleAdultWeight: 4.5,
    femaleAdultWeight: 4.5,
    isMoggie: false,
    isPedigree: true,
  },
  {
    value: "SOMALI",
    text: "Somali",
    maleAdultWeight: 4,
    femaleAdultWeight: 4,
    isMoggie: false,
    isPedigree: true,
  },
  {
    value: "SPHYNX",
    text: "Sphynx",
    maleAdultWeight: 4.5,
    femaleAdultWeight: 4,
    isMoggie: false,
    isPedigree: true,
  },
  {
    value: "TIFFANIE",
    text: "Tiffanie",
    maleAdultWeight: 4,
    femaleAdultWeight: 4,
    isMoggie: false,
    isPedigree: true,
  },
  {
    value: "TONKINESE",
    text: "Tonkinese",
    maleAdultWeight: 4.5,
    femaleAdultWeight: 4.5,
    isMoggie: false,
    isPedigree: true,
  },
  {
    value: "TURKISH_ANGORA",
    text: "Turkish Angora",
    maleAdultWeight: 5,
    femaleAdultWeight: 4,
    isMoggie: false,
    isPedigree: true,
  },
  {
    value: "TURKISH_VAN",
    text: "Turkish Van",
    maleAdultWeight: 4.5,
    femaleAdultWeight: 4.5,
    isMoggie: false,
    isPedigree: true,
  },
  {
    value: "OTHER",
    text: "Other",
    maleAdultWeight: 5,
    femaleAdultWeight: 4.3,
    isMoggie: true,
    isPedigree: false,
  },
  {
    value: "I_DONT_KNOW_THEIR_BREED",
    text: "I don't know their breed",
    maleAdultWeight: 5,
    femaleAdultWeight: 4.3,
    isMoggie: true,
    isPedigree: false,
  },
];

export const CAT_FOOD_TYPE = {
  Wet: "Wet",
  Dry: "Dry",
  "Dry,Wet": "Wet & Dry",
  "Home-cooked": "Home-cooked",
  Raw: "Raw",
  Fresh: "Fresh",
} as const;

export const CAT_FOOD_BRAND = {
  FELIX: "Felix",
  WHISKAS: "Whiskas",
  GOURMET: "Gormet",
  SHEBA: "Sheba",
  ROYAL_CANIN: "Royal Canin",
  PURINA_ONE: "Purina one",
  APPLAWS: "Applaws",
  AARDVARK: "Aardvark",
  ALMO_NATURE: "Almo nature",
  AVA: "Ava",
  BLINK: "Blink",
  BURNS: "Burns",
  CARNILOVE: "Carnilove",
  CRAVE: "Crave",
  EDGARD_COOPER: "Egard Cooper",
  ENCORE: "Encore",
  FERINGA: "Fergina",
  FRESHPET: "Freshpet",
  GO_CAT: "Go Cat",
  HARRINGTONS: "Harringtons",
  HILLS: "Hills",
  IAMS: "Iams",
  JAMES_WELLBELOVED: "James Wellbeloved",
  KATKIN: "Katkin",
  LILYS_KITCHEN: "Lily's Kitchen",
  MEOWING_HEADS: "Meowing heads",
  NATURAL_INSTINCT: "Natural instinct",
  NATURES_MENU: "Natures menu",
  NUTRIMENT: "Nutriment",
  ORIJEN: "Origen",
  "PURR&MIAOW": "Purr & Meow",
  PURRFORM: "Purrform",
  REPUBLIC_OF_CATS: "Republic of Cats",
  SCRUMBLES: "Scrumbles",
  SUPERMARKET_BRAND: "Supermarket brand",
  THRIVE: "Thrive",
  TOMOJO: "Tomojo",
  UNTAMED: "Untamed",
  OTHER: "Other",
} as const;

export const CAT_ACTIVITY_LEVEL = {
  INACTIVE: "Inactive",
  ACTIVE: "Active",
  EXPLORER: "Explorer",
  UNKNOWN: "Unknown",
} as const;

export const CAT_BODY_TYPE = {
  SKINNY: "Skinny",
  THIN: "Thin",
  JUST_RIGHT: "Just right",
  CHUBBY: "Chubby",
  OBESE: "Obese",
  UNKNOWN: "Unknown",
} as const;

export type TFoodType = keyof typeof CAT_FOOD_TYPE;
export type TBodyType = keyof typeof CAT_BODY_TYPE;
export type TLifeStyle = keyof typeof CAT_ACTIVITY_LEVEL;
export type TFoodBrand = keyof typeof CAT_FOOD_BRAND;

export interface ICompetitorFood {
  costPerDay: number;
  meatPercentage: number;
}

type TCompetitorPortion = Record<PouchSize, ICompetitorFood>;
type TCompetitorType = Partial<Record<TFoodType, TCompetitorPortion>>;

type TCompetitorMap = Partial<Record<TFoodBrand, TCompetitorType>>;

export const COMPETITOR_MAP_LAST_UPDATED = "19/08/2024";
export const competitorMap: TCompetitorMap = {
  WHISKAS: {
    Dry: {
      A: {
        costPerDay: 0.11,
        meatPercentage: 4,
      },
      B: {
        costPerDay: 0.15,
        meatPercentage: 4,
      },
      C: {
        costPerDay: 0.18,
        meatPercentage: 4,
      },
      D: {
        costPerDay: 0.22,
        meatPercentage: 4,
      },
      E: {
        costPerDay: 0.26,
        meatPercentage: 4,
      },
      F: {
        costPerDay: 0.29,
        meatPercentage: 4,
      },
    },
    "Dry,Wet": {
      A: {
        costPerDay: 0.53,
        meatPercentage: 4,
      },
      B: {
        costPerDay: 0.71,
        meatPercentage: 4,
      },
      C: {
        costPerDay: 0.89,
        meatPercentage: 4,
      },
      D: {
        costPerDay: 1.06,
        meatPercentage: 4,
      },
      E: {
        costPerDay: 1.24,
        meatPercentage: 4,
      },
      F: {
        costPerDay: 1.42,
        meatPercentage: 4,
      },
    },
    Wet: {
      A: {
        costPerDay: 0.95,
        meatPercentage: 4,
      },
      B: {
        costPerDay: 1.27,
        meatPercentage: 4,
      },
      C: {
        costPerDay: 1.59,
        meatPercentage: 4,
      },
      D: {
        costPerDay: 1.91,
        meatPercentage: 4,
      },
      E: {
        costPerDay: 2.22,
        meatPercentage: 4,
      },
      F: {
        costPerDay: 2.54,
        meatPercentage: 4,
      },
    },
  },
  FELIX: {
    "Dry,Wet": {
      A: {
        costPerDay: 0.38,
        meatPercentage: 4,
      },
      B: {
        costPerDay: 0.61,
        meatPercentage: 4,
      },
      C: {
        costPerDay: 0.76,
        meatPercentage: 4,
      },
      D: {
        costPerDay: 0.91,
        meatPercentage: 4,
      },
      E: {
        costPerDay: 1.07,
        meatPercentage: 4,
      },
      F: {
        costPerDay: 1.22,
        meatPercentage: 4,
      },
    },
    Wet: {
      A: {
        costPerDay: 0.75,
        meatPercentage: 4,
      },
      B: {
        costPerDay: 1.0,
        meatPercentage: 4,
      },
      C: {
        costPerDay: 1.25,
        meatPercentage: 4,
      },
      D: {
        costPerDay: 1.5,
        meatPercentage: 4,
      },
      E: {
        costPerDay: 1.75,
        meatPercentage: 4,
      },
      F: {
        costPerDay: 2.0,
        meatPercentage: 4,
      },
    },
  },
  SHEBA: {
    "Dry,Wet": {
      A: {
        costPerDay: 1.27,
        meatPercentage: 4,
      },
      B: {
        costPerDay: 1.69,
        meatPercentage: 4,
      },
      C: {
        costPerDay: 2.12,
        meatPercentage: 4,
      },
      D: {
        costPerDay: 2.54,
        meatPercentage: 4,
      },
      E: {
        costPerDay: 2.97,
        meatPercentage: 4,
      },
      F: {
        costPerDay: 3.39,
        meatPercentage: 4,
      },
    },
    Wet: {
      A: {
        costPerDay: 1.27,
        meatPercentage: 4,
      },
      B: {
        costPerDay: 1.69,
        meatPercentage: 4,
      },
      C: {
        costPerDay: 2.12,
        meatPercentage: 4,
      },
      D: {
        costPerDay: 2.54,
        meatPercentage: 4,
      },
      E: {
        costPerDay: 2.97,
        meatPercentage: 4,
      },
      F: {
        costPerDay: 3.39,
        meatPercentage: 4,
      },
    },
  },
  PURINA_ONE: {
    Dry: {
      A: {
        costPerDay: 0.36,
        meatPercentage: 17,
      },
      B: {
        costPerDay: 0.47,
        meatPercentage: 17,
      },
      C: {
        costPerDay: 0.59,
        meatPercentage: 17,
      },
      D: {
        costPerDay: 0.71,
        meatPercentage: 17,
      },
      E: {
        costPerDay: 0.83,
        meatPercentage: 17,
      },
      F: {
        costPerDay: 0.95,
        meatPercentage: 17,
      },
    },
    "Dry,Wet": {
      A: {
        costPerDay: 0.57,
        meatPercentage: 4,
      },
      B: {
        costPerDay: 0.76,
        meatPercentage: 4,
      },
      C: {
        costPerDay: 0.95,
        meatPercentage: 4,
      },
      D: {
        costPerDay: 1.14,
        meatPercentage: 4,
      },
      E: {
        costPerDay: 1.33,
        meatPercentage: 4,
      },
      F: {
        costPerDay: 1.53,
        meatPercentage: 4,
      },
    },
  },
  GOURMET: {
    "Dry,Wet": {
      A: {
        costPerDay: 0.94,
        meatPercentage: 4,
      },
      B: {
        costPerDay: 1.26,
        meatPercentage: 4,
      },
      C: {
        costPerDay: 1.57,
        meatPercentage: 4,
      },
      D: {
        costPerDay: 1.89,
        meatPercentage: 4,
      },
      E: {
        costPerDay: 2.2,
        meatPercentage: 4,
      },
      F: {
        costPerDay: 2.51,
        meatPercentage: 4,
      },
    },
    Wet: {
      A: {
        costPerDay: 0.94,
        meatPercentage: 4,
      },
      B: {
        costPerDay: 1.26,
        meatPercentage: 4,
      },
      C: {
        costPerDay: 1.57,
        meatPercentage: 4,
      },
      D: {
        costPerDay: 1.89,
        meatPercentage: 4,
      },
      E: {
        costPerDay: 2.2,
        meatPercentage: 4,
      },
      F: {
        costPerDay: 2.51,
        meatPercentage: 4,
      },
    },
  },
  ROYAL_CANIN: {
    "Dry,Wet": {
      A: {
        costPerDay: 1.71,
        meatPercentage: 0,
      },
      B: {
        costPerDay: 2.28,
        meatPercentage: 0,
      },
      C: {
        costPerDay: 2.85,
        meatPercentage: 0,
      },
      D: {
        costPerDay: 3.42,
        meatPercentage: 0,
      },
      E: {
        costPerDay: 3.99,
        meatPercentage: 0,
      },
      F: {
        costPerDay: 4.56,
        meatPercentage: 0,
      },
    },
    Dry: {
      A: {
        costPerDay: 0.43,
        meatPercentage: 0,
      },
      B: {
        costPerDay: 0.58,
        meatPercentage: 0,
      },
      C: {
        costPerDay: 0.72,
        meatPercentage: 0,
      },
      D: {
        costPerDay: 0.87,
        meatPercentage: 0,
      },
      E: {
        costPerDay: 1.01,
        meatPercentage: 0,
      },
      F: {
        costPerDay: 1.16,
        meatPercentage: 0,
      },
    },
  },
  SUPERMARKET_BRAND: {
    "Dry,Wet": {
      A: {
        costPerDay: 0.21,
        meatPercentage: 38,
      },
      B: {
        costPerDay: 0.28,
        meatPercentage: 38,
      },
      C: {
        costPerDay: 0.35,
        meatPercentage: 38,
      },
      D: {
        costPerDay: 0.43,
        meatPercentage: 38,
      },
      E: {
        costPerDay: 0.5,
        meatPercentage: 38,
      },
      F: {
        costPerDay: 0.57,
        meatPercentage: 38,
      },
    },
  },
  APPLAWS: {
    "Dry,Wet": {
      A: {
        costPerDay: 1.32,
        meatPercentage: 75,
      },
      B: {
        costPerDay: 1.76,
        meatPercentage: 75,
      },
      C: {
        costPerDay: 1.76,
        meatPercentage: 75,
      },
      D: {
        costPerDay: 2.21,
        meatPercentage: 75,
      },
      E: {
        costPerDay: 2.65,
        meatPercentage: 75,
      },
      F: {
        costPerDay: 3.09,
        meatPercentage: 75,
      },
    },
  },
  IAMS: {
    "Dry,Wet": {
      A: {
        costPerDay: 1.05,
        meatPercentage: 26,
      },
      B: {
        costPerDay: 1.4,
        meatPercentage: 26,
      },
      C: {
        costPerDay: 1.75,
        meatPercentage: 26,
      },
      D: {
        costPerDay: 2.21,
        meatPercentage: 26,
      },
      E: {
        costPerDay: 2.65,
        meatPercentage: 26,
      },
      F: {
        costPerDay: 3.09,
        meatPercentage: 26,
      },
    },
  },
  UNTAMED: {
    Wet: {
      A: {
        costPerDay: 2.14,
        meatPercentage: 60,
      },
      B: {
        costPerDay: 2.85,
        meatPercentage: 60,
      },
      C: {
        costPerDay: 3.57,
        meatPercentage: 60,
      },
      D: {
        costPerDay: 4.28,
        meatPercentage: 60,
      },
      E: {
        costPerDay: 4.99,
        meatPercentage: 60,
      },
      F: {
        costPerDay: 5.7,
        meatPercentage: 60,
      },
    },
  },
};
