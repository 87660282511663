import {
  BODY_TYPE_CALORIE_MULTIPLIER,
  BREEDS,
  CALORIE_THRESHOLD,
  GROWTH_CURVE,
  LIFESTYLE_CALORIE_MULTIPLIER,
  MEAL_PLAN_CALORIES,
  RETAIL_PLAN_PORTION,
  WEEKS_PER_MONTH,
} from "./cat-calculations.constants";
import {
  BodyType,
  Breed,
  Gender,
  ICatAge,
  IFraction,
  IMealPlan,
  IRetailTrayPortion,
  LifeStyle,
  Portion,
  PouchSize,
  TDecimalRounding,
} from "./cat-calculations.types";

export const calculateCatWeight = (
  breed: Breed,
  age: ICatAge,
  gender: Gender,
): number => {
  let multiplier = 100;
  const breedDetails = BREEDS.find(
    (mappedBreed) => mappedBreed.value === breed,
  );
  if (!breed || !age || !gender || !breedDetails) return 0;

  if (age.type === "kitten") {
    const weekSelector = Number(
      (((age.months || 0) * 4.33 + (age.weeks || 0)) / 2).toFixed(0),
    );
    multiplier = GROWTH_CURVE[weekSelector];
  }

  return Number(
    (
      ((gender && gender === "BOY"
        ? breedDetails.maleAdultWeight
        : breedDetails.femaleAdultWeight) *
        multiplier) /
      100
    ).toFixed(1),
  );
};

export const range = (
  start: number,
  stop: number,
) => Array.from({ length: stop - start + 1 }, (value, index) => start + index);

export const isKitten = (age: ICatAge) => {
  if (age.type && [ "adult", "senior" ].includes(age.type)) return false;
  if (age.years && age.years >= 1) return false;
  if (age.months && age.months >= 12) return false;
  if (age.weeks && age.weeks >= 52) return false;

  return true;
};

export const calculateSuggestedCaloriesForKitten = (
  weight: number,
  age: ICatAge,
): number => {
  const ageInWeeks = Math.round(
    (Number(age.weeks) || 0) + (Number(age.months) || 0) * WEEKS_PER_MONTH,
  );
  const matureBodyWeight =
    weight /
    ((-0.0474 * (ageInWeeks * ageInWeeks) + (4.47 * ageInWeeks - 5.5784)) /
      100);

  if (range(1, 14).includes(ageInWeeks)) {
    return Math.floor(
      100 *
        weight ** 0.67 *
        6.7 *
        (2.718 ** (-0.189 * (weight / matureBodyWeight)) - 0.66),
    );
  }
  if (range(15, 40).includes(ageInWeeks)) return Math.floor(1.75 * 50 * matureBodyWeight);
  if (range(41, 48).includes(ageInWeeks)) return Math.floor(1.5 * 50 * matureBodyWeight);
  return Math.floor(1.25 * 50 * matureBodyWeight);
};

export const calculateSuggestedCaloriesForGrownCat = (
  weight: number,
  bodyType: BodyType,
  lifestyle: LifeStyle,
): number => {
  const idealBodyWeight =
    (weight / BODY_TYPE_CALORIE_MULTIPLIER[bodyType]) *
    LIFESTYLE_CALORIE_MULTIPLIER[lifestyle];
  return Math.round(idealBodyWeight * 50);
};

export const calculateSuggestedCalories = (
  weight: number,
  bodyType: BodyType,
  lifestyle: LifeStyle,
  age: ICatAge,
): number => {
  if (isKitten(age)) {
    return calculateSuggestedCaloriesForKitten(weight, age);
  }
  return calculateSuggestedCaloriesForGrownCat(weight, bodyType, lifestyle);
};

export const calculateTraySize = (caloriesNeeded: number): IMealPlan => {
  const trays = Object.entries(MEAL_PLAN_CALORIES);

  for (const mealPlan of trays) {
    if (mealPlan) {
      const [ size, calories ]: [string, number] = mealPlan;
      if (caloriesNeeded <= calories + CALORIE_THRESHOLD) {
        return {
          size: size as PouchSize,
          calories,
        };
      }
    }
  }
  const [ size, calories ]: [string, number] = trays[trays.length - 1];

  return {
    size: size as PouchSize,
    calories,
  };
};

export const calculateRetailTrayServings = (
  caloriesNeeded: number,
): IRetailTrayPortion => {
  const trays = Object.entries(RETAIL_PLAN_PORTION);

  for (const mealPlan of trays) {
    if (mealPlan) {
      const [ calories, portion ] = mealPlan;
      if (caloriesNeeded <= +calories + CALORIE_THRESHOLD) {
        return {
          portion: portion as Portion,
          calories: +calories,
        };
      }
    }
  }
  const [ calories, portion ]: [string, number] = trays[trays.length - 1];

  return {
    portion: portion as Portion,
    calories: +calories,
  };
};

export const calculateSuggestedCatTraySize = (
  weight: number,
  bodyType: BodyType,
  lifestyle: LifeStyle,
  age: ICatAge,
): IMealPlan => {
  const suggestedCalories = calculateSuggestedCalories(
    weight,
    bodyType,
    lifestyle,
    age,
  );
  return calculateTraySize(suggestedCalories);
};

const getGreatestCommonDivisor = (a: number, b: number): number => (a % b ? getGreatestCommonDivisor(b, a % b) : b);

export const makeFractionFromDecimal = (
  decimal: number,
  denominator: number,
  rounding: TDecimalRounding = "UP",
): IFraction => {
  const factor: number = 100 / denominator;
  const numeratorTotal = (decimal * 100) / factor;
  const wholeNumber = Math.floor(numeratorTotal / denominator);
  let decimalPlaces = numeratorTotal / denominator - wholeNumber;

  if (rounding === "UP") {
    decimalPlaces = Math.ceil(decimalPlaces / (factor / 100)) * (factor / 100);
  } else {
    decimalPlaces = Math.floor(decimalPlaces / (factor / 100)) * (factor / 100);
  }
  const numerator = (decimalPlaces * 100) / factor;

  const gcd = getGreatestCommonDivisor(numerator, denominator);
  const numeratorAdjustedForLowestCommonDenominator = numerator / gcd;
  const denominatorAdjustedForLowestCommonDenominator = denominator / gcd;

  return {
    wholeNumber: wholeNumber > 0 ? wholeNumber : undefined,
    fraction:
      numeratorAdjustedForLowestCommonDenominator > 0
        ? {
          numerator: numeratorAdjustedForLowestCommonDenominator,
          denominator: denominatorAdjustedForLowestCommonDenominator,
        }
        : undefined,
  };
};
