import {
  INVALID_AREAS,
  INVALID_POSTCODES,
  POSTCODE_ERRORS,
  VALID_POSTCODE_FORMAT,
} from "./address.constants";
import {
  IAddress,
  IGetAddressResponse,
  IPostcodeCheckResponse,
} from "./address.types";
import { sanitizePostcode } from "./address.utils";

export function Address(): IAddress {

  const postcodeCheck = (postcode: string, skipOutOfBounds = false): IPostcodeCheckResponse => {
    const sanitizedPostcode = sanitizePostcode(postcode);
    if (!VALID_POSTCODE_FORMAT.test(sanitizedPostcode)) return { sanitized: null, errorType: "BadPostcodeFormat", message: POSTCODE_ERRORS.BadPostcodeFormat };
    if (skipOutOfBounds) return { sanitized: sanitizedPostcode, errorType: null };
    const firstPart = sanitizedPostcode.split(" ")[0];
    if (INVALID_POSTCODES.includes(firstPart)) return { sanitized: null, errorType: "DoNotDeliverToPostcode", message: POSTCODE_ERRORS.DoNotDeliverToPostcode };
    if (INVALID_AREAS.some((area) => firstPart.startsWith(area))) return {
      sanitized: null,
      errorType: "DoNotDeliverToPostcode",
      message: POSTCODE_ERRORS.DoNotDeliverToPostcode,
    };
    return { sanitized: sanitizedPostcode, errorType: null };
  };

  const fetchAddressFromPostcode = async (postcode: string): Promise<IGetAddressResponse> => {
    const sanitizedPostcode = sanitizePostcode(postcode);
    const response = await fetch(`https://api.getAddress.io/find/${sanitizedPostcode}?api-key=dyvE3JfD-kW7Xapg5VQyiw35217&expand=true&sort=true`, {
      method: "GET",
    });
    if (response.ok) return response.json();
    const body = await response.text();
    throw new Error(`Something went wrong: ${response.status}: ${body}`);
  };

  return {
    fetchAddressFromPostcode,
    postcodeCheck,
  };
}

export default Address;
